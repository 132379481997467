@import "src/variable";

.ab-SimpleButton {
     font-size: 13px !important;
}

// Override form-control style
.form-control-plaintext {
     color: black !important;
 }

//#region Scroll Bar
::-webkit-scrollbar {
     width: 10px;
     height: 10px;
}

::-webkit-scrollbar-thumb {
     background: $grey-light;
     border-radius: 10px;
     &:hover {
          background: $grey;
     }
}

::-webkit-scrollbar-track {
     background: white;
}
::-webkit-scrollbar-corner {
     background: white;
}
//#endregion

//#region Misc.
.build_edit_tabs + .tab-content {
     flex-grow: 1;
}
//#endregion

//#region Form.io
.formio-dialog.formio-dialog-theme-default .formio-dialog-content {
     background-color: #ffffff;
}

.form-group.has-feedback.formio-component.formio-component-datagrid.formio-component-values {
     all: initial;
}
//#endregion

.tooltip {
     .tooltip-inner {
          max-width: 50vw;
     }
}

//#region Toast Overrule
.bg-info {
     > .toast-header {
          background-color: #27bcfd;
          color: white !important;
     }
     > .toast-body {
          background-color: #dff5ffcf !important;
     }
}
.bg-danger {
     > .toast-header {
          background-color: #e63757;
          color: white !important;
     }
     > .toast-body {
          background-color: #fbe1e6cf !important;
     }
}
.bg-success {
     > .toast-header {
          background-color: #00d27a;
          color: white !important;
     }
     > .toast-body {
          background-color: #d9f8ebcf !important;
     }
}
.bg-warning {
     > .toast-header {
          background-color: #f5803e;
          color: white !important;
     }
     > .toast-body {
          background-color: #feece2cf !important;
     }
}
//#endregion