.modal-content,
.modal-body,
.modal-footer {
  button.btn:disabled {
    visibility: visible;
  }

  .requiredSpan {
    color: red;
  }
  .header-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input,
  textarea,
  select {
    &.invalid {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(20.75em + 0.375rem);
    }
  }

  .invalid-feedback.active {
    display: block;
  }

  .alert-imaged {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.loader_bg_modal {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  position: fixed;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .loader_message {
    color: white;
    font-size: larger;
    font-weight: bolder;
    margin-top: -12.5vh;
  }

  &.error_dark {
    background-color: rgba(0, 0, 0, 0.75);
  }
}

.img_modal {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
  }

  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }

  .close_button {
    position: fixed;
    top: 0;
    right: 0;
  }

  .img_modal_container {
    display: flex;
    flex-direction: column;
    color: white;
    min-height: 75vh;
    justify-content: space-around;

    .img_modal_top {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;

      .img_modal_top_side {
        .switch {
          cursor: pointer;
          color: white;
        }
      }

      .img_modal_top_middle {
        text-align: center;
        img {
          max-width: 85%;
          max-height: 75vh;
        }

        .src_fail {
          font-size: larger;
          font-weight: bold;
        }
      }
    }

    .img_modal_bottom {
      height: 5vh;
      max-height: 140px;
      text-align: center;
    }
  }
}

.draggable-dialog {
  position: absolute;
 
  
  button, input, textarea, select {
    cursor: auto;
  }
  
  button:not(:disabled) {
    cursor: pointer;
  }
  
  .modal-content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .modal-header {
    user-select: none;
    padding: 1rem;
    cursor: move;
    flex-shrink: 0;

    .modal-controls {
      display: flex;
      align-items: center;

      .maximize-button {
        border: none;
        background: none;
        padding: 0 4px;
        cursor: pointer;
        color: #666;
        transition: color 0.2s;
        height: 32px;
        display: flex;
        align-items: center;

        &:hover {
          color: #000;
        }

        i {
          font-size: 1.1rem;
        }
      }
    }
  }

  .modal-body {
    flex: 1;
    position: relative;
    padding: 1rem;
    padding-right: 1.15rem;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 100px); /* Ajustez selon vos besoins */

    &.maximized {
      max-height: 100vh !important;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
      
      &:hover {
        background: #555;
      }
    }
  }

  .modal-footer {
    flex-shrink: 0;
  }

  .custom-handle {
    position: absolute;
    background: transparent;
    z-index: 1;

    &.custom-handle-s {
      bottom: -12px;
      left: 0;
      right: 0;
      height: 24px;
      cursor: s-resize;
    }

    &.custom-handle-w {
      left: -12px;
      top: 0;
      bottom: 0;
      width: 24px;
      cursor: w-resize;
    }

    &.custom-handle-e {
      right: -12px;
      top: 0;
      bottom: 0;
      width: 20px;
      clip-path: inset(0 0 0 5px);
      cursor: e-resize;
    }

    &.custom-handle-n {
      top: -12px;
      left: 0;
      right: 0;
      height: 24px;
      cursor: n-resize;
    }

    &.custom-handle-sw {
      bottom: -12px;
      left: -12px;
      width: 24px;
      height: 24px;
      cursor: sw-resize;
    }

    &.custom-handle-nw {
      top: -12px;
      left: -12px;
      width: 24px;
      height: 24px;
      cursor: nw-resize;
    }

    &.custom-handle-se {
      bottom: -12px;
      right: -12px;
      width: 24px;
      height: 24px;
      clip-path: inset(0 5px 8px 0);
      cursor: se-resize;
    }

    &.custom-handle-ne {
      top: -12px;
      right: -12px;
      width: 24px;
      height: 24px;
      clip-path: inset(8px 5px 0 0);
      cursor: ne-resize;
    }
  }

  .react-resizable {
    position: relative;
    background-color: white;
    overflow: visible !important;
  }

  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    opacity: 0;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}

.MuiDialog-paper {
  position: absolute !important;
  margin: 0 !important;
  min-width: 200px !important;
  min-height: 100px !important;
  overflow: visible !important;
}

.MuiDialogContent-root {
  overflow: visible !important;
  padding: 0 !important;
}

.react-resizable {
  position: relative;
  background-color: white;
  overflow: visible !important;
}

.animated_scale_in {
  animation: scaleIn 0.2s ease-in-out;
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 700px) {
  .draggable-dialog {
    .modal-header {
      flex-direction: column;
      align-items: stretch;
      text-align: center;
      gap: 1rem;

      .modal-title {
        margin: 0;
      }

      .ms-2 {
        margin: 0;
      }

      .btn-close {
        align-self: flex-end;
      }
    }

    .modal-footer {
      flex-direction: column;
      gap: 0.5rem;

      > * {
        width: 100%;
      }
    }
  }
}